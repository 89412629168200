// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.loder_loaderWrapper__SGlws {
  width: 100%;
  height: 200px;
  display: grid;
  place-items: center;
  grid-column: 1/9;
  background-color: #fff;
}

.loder_loader__8xu1X {
  width: 10px;
  aspect-ratio: 1;
  border-radius: 50%;
  animation: loder_l5__qQCvh 1s infinite linear alternate;
}

@keyframes loder_l5__qQCvh {
  0% {
    box-shadow: 20px 0 #ccc, -20px 0 rgba(204, 204, 204, 0.1333333333);
    background: #ccc;
  }
  33% {
    box-shadow: 20px 0 #ccc, -20px 0 rgba(204, 204, 204, 0.1333333333);
    background: rgba(204, 204, 204, 0.1333333333);
  }
  66% {
    box-shadow: 20px 0 rgba(204, 204, 204, 0.1333333333), -20px 0 #ccc;
    background: rgba(204, 204, 204, 0.1333333333);
  }
  100% {
    box-shadow: 20px 0 rgba(204, 204, 204, 0.1333333333), -20px 0 #ccc;
    background: #ccc;
  }
}`, "",{"version":3,"sources":["webpack://./src/components/loader/loder.module.scss"],"names":[],"mappings":"AAAA;EACE,WAAA;EACA,aAAA;EACA,aAAA;EACA,mBAAA;EACA,gBAAA;EACA,sBAAA;AACF;;AAEA;EACE,WAAA;EACA,eAAA;EACA,kBAAA;EACA,uDAAA;AACF;;AAEA;EACE;IACE,kEACE;IAEF,gBAAA;EADF;EAGA;IACE,kEACE;IAEF,6CAAA;EAHF;EAKA;IACE,kEACE;IAEF,6CAAA;EALF;EAOA;IACE,kEACE;IAEF,gBAAA;EAPF;AACF","sourcesContent":[".loaderWrapper {\n  width: 100%;\n  height: 200px;\n  display: grid;\n  place-items: center;\n  grid-column: 1/9;\n  background-color: #fff;\n}\n\n.loader {\n  width: 10px;\n  aspect-ratio: 1;\n  border-radius: 50%;\n  animation: l5 1s infinite linear alternate;\n}\n\n@keyframes l5 {\n  0% {\n    box-shadow:\n      20px 0 #ccc,\n      -20px 0 #ccc2;\n    background: #ccc;\n  }\n  33% {\n    box-shadow:\n      20px 0 #ccc,\n      -20px 0 #ccc2;\n    background: #ccc2;\n  }\n  66% {\n    box-shadow:\n      20px 0 #ccc2,\n      -20px 0 #ccc;\n    background: #ccc2;\n  }\n  100% {\n    box-shadow:\n      20px 0 #ccc2,\n      -20px 0 #ccc;\n    background: #ccc;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"loaderWrapper": `loder_loaderWrapper__SGlws`,
	"loader": `loder_loader__8xu1X`,
	"l5": `loder_l5__qQCvh`
};
export default ___CSS_LOADER_EXPORT___;
