// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.header-item_headerItem__jE91U {
  font-weight: 600;
  font-size: 12px;
  padding: 12px;
  background-color: #f1f3fa;
  display: flex;
  flex-direction: row;
  align-items: center;
  -webkit-user-select: none;
          user-select: none;
  color: #2d2d2d;
}

.header-item_clickable__N8UIH {
  cursor: pointer;
  transition: 0.2s ease;
}
.header-item_clickable__N8UIH:hover {
  background-color: #eceffa;
}`, "",{"version":3,"sources":["webpack://./src/components/crypto-table/header/header-item/header-item.module.scss"],"names":[],"mappings":"AAAA;EACE,gBAAA;EACA,eAAA;EACA,aAAA;EACA,yBAAA;EACA,aAAA;EACA,mBAAA;EACA,mBAAA;EACA,yBAAA;UAAA,iBAAA;EACA,cAAA;AACF;;AAEA;EACE,eAAA;EACA,qBAAA;AACF;AACE;EACE,yBAAA;AACJ","sourcesContent":[".headerItem {\n  font-weight: 600;\n  font-size: 12px;\n  padding: 12px;\n  background-color: #f1f3fa;\n  display: flex;\n  flex-direction: row;\n  align-items: center;\n  user-select: none;\n  color: #2d2d2d;\n}\n\n.clickable {\n  cursor: pointer;\n  transition: 0.2s ease;\n\n  &:hover {\n    background-color: #eceffa;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"headerItem": `header-item_headerItem__jE91U`,
	"clickable": `header-item_clickable__N8UIH`
};
export default ___CSS_LOADER_EXPORT___;
