// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.badge_badge__OatI- {
  font-size: 12px;
  line-height: 16px;
  background-color: #f4f4f5;
  color: #2d2d2d;
  padding: 4px 8px;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
  font-weight: 500;
  border-radius: 8px;
}`, "",{"version":3,"sources":["webpack://./src/components/crypto-table/badge/badge.module.scss"],"names":[],"mappings":"AAAA;EACE,eAAA;EACA,iBAAA;EACA,yBAAA;EACA,cAAA;EACA,gBAAA;EACA,aAAA;EACA,mBAAA;EACA,mBAAA;EACA,QAAA;EACA,gBAAA;EACA,kBAAA;AACF","sourcesContent":[".badge {\n  font-size: 12px;\n  line-height: 16px;\n  background-color: #f4f4f5ff;\n  color: #2d2d2d;\n  padding: 4px 8px;\n  display: flex;\n  flex-direction: row;\n  align-items: center;\n  gap: 8px;\n  font-weight: 500;\n  border-radius: 8px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"badge": `badge_badge__OatI-`
};
export default ___CSS_LOADER_EXPORT___;
