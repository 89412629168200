import React, { useContext, useEffect, useMemo, useRef, useState } from "react";

import { TableHeader } from "./header/table-header";
import { TableContent } from "components/crypto-table/content/table-content";
import { Loader } from "components/loader/loader";
import { CryptoDetails } from "types";
import { sortData } from "./crypto-table.utils";
import { TableContext } from "context";

import styles from "./crypto-table.module.scss";

export const CryptoTable: React.FC = () => {
  const dataRef = useRef<CryptoDetails[]>([]);

  const { order } = useContext(TableContext);

  const [data, setData] = useState<CryptoDetails[]>([]);
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    (async () => {
      const fetchData = async () => {
        const response = await fetch("https://api-ci.tiwariportfolio.com/coins");
        const cryptoData = await response.json();

        dataRef.current = cryptoData;
        setData(cryptoData);
        setLoading(false);
      };

      await fetchData();

      const interval = setInterval(fetchData, 60 * 1000);

      return () => {
        clearInterval(interval);
      };
    })();
  }, []);

  const sortedData = useMemo(() => {
    return sortData(dataRef.current, order);
  }, [data, order]);

  return (
    <div className={styles.wrapper}>
      <div className={styles.content}>
        <TableHeader />

        {loading && <Loader />}
        {!loading && <TableContent data={sortedData} />}
      </div>
    </div>
  );
};
