// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.crypto-table_wrapper__BjMsP {
  width: calc(100% - 24px);
  overflow: auto;
  border: 1px solid #e5e5e5;
  box-shadow: 0 2px 30px 0 rgba(0, 0, 0, 0.08);
  border-radius: 12px;
  background-color: #e4eef8;
  margin: 12px;
}

.crypto-table_content__8Vf-u {
  width: 100%;
  display: grid;
  min-width: 600px;
  grid-template-columns: minmax(140px, 200px) minmax(160px, 200px) repeat(5, fit-content(200px));
  grid-gap: 1px;
  border-radius: 12px;
}
@media screen and (min-width: 880px) {
  .crypto-table_content__8Vf-u {
    grid-template-columns: minmax(180px, 200px) minmax(160px, 200px) repeat(5, minmax(140px, 1fr));
    min-width: 800px;
  }
}
@media screen and (min-width: 1200px) {
  .crypto-table_content__8Vf-u {
    grid-template-columns: minmax(190px, 260px) minmax(170px, 200px) repeat(5, minmax(140px, 1fr));
  }
}

.crypto-table_headerItem__eJ04d {
  font-weight: 600;
  font-size: 12px;
  padding: 12px;
  background-color: #e5e5e5;
}`, "",{"version":3,"sources":["webpack://./src/components/crypto-table/crypto-table.module.scss"],"names":[],"mappings":"AAAA;EACE,wBAAA;EACA,cAAA;EAEA,yBAAA;EACA,4CAAA;EACA,mBAAA;EACA,yBAAA;EACA,YAAA;AAAF;;AAGA;EACE,WAAA;EACA,aAAA;EACA,gBAAA;EACA,8FAAA;EACA,aAAA;EACA,mBAAA;AAAF;AAEE;EARF;IASI,8FAAA;IACA,gBAAA;EACF;AACF;AACE;EAbF;IAcI,8FAAA;EAEF;AACF;;AACA;EACE,gBAAA;EACA,eAAA;EACA,aAAA;EACA,yBAAA;AAEF","sourcesContent":[".wrapper {\n  width: calc(100% - 24px);\n  overflow: auto;\n\n  border: 1px solid #e5e5e5;\n  box-shadow: 0 2px 30px 0 rgba(0, 0, 0, 0.08);\n  border-radius: 12px;\n  background-color: #e4eef8;\n  margin: 12px;\n}\n\n.content {\n  width: 100%;\n  display: grid;\n  min-width: 600px;\n  grid-template-columns: minmax(140px, 200px) minmax(160px, 200px) repeat(5, fit-content(200px));\n  grid-gap: 1px;\n  border-radius: 12px;\n\n  @media screen and (min-width: 880px) {\n    grid-template-columns: minmax(180px, 200px) minmax(160px, 200px) repeat(5, minmax(140px, 1fr));\n    min-width: 800px;\n  }\n\n  @media screen and (min-width: 1200px) {\n    grid-template-columns: minmax(190px, 260px) minmax(170px, 200px) repeat(5, minmax(140px, 1fr));\n  }\n}\n\n.headerItem {\n  font-weight: 600;\n  font-size: 12px;\n  padding: 12px;\n  background-color: #e5e5e5;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": `crypto-table_wrapper__BjMsP`,
	"content": `crypto-table_content__8Vf-u`,
	"headerItem": `crypto-table_headerItem__eJ04d`
};
export default ___CSS_LOADER_EXPORT___;
